<template>
  <div class="mt-3">
    <b-row>
      <h6 class="page-title pb-2 mt-3">
        {{ $t("risk-management.title") }}
      </h6>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-overlay
          :show="sending"
          rounded
          opacity="0.7"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block me-4"
        >
          <b-button class="my-4 main-btn" @click="downloadTemplate">
            <b-icon-file-earmark-arrow-down class="me-1" />
            Obtener Plantilla
          </b-button>
        </b-overlay>

        <span class="file-input me-4">
          <label for="image" class="btn main-btn btn-secondary">
            <b-icon-file-earmark-arrow-up class="me-1" />
            Cargar Plantilla
          </label>
          <input
            id="image"
            ref="fileTemplate"
            type="file"
            accept="*"
            multiple
            @change="onInputChange"
          />
        </span>

        <span class="me-4" v-if="file">
          {{ file.name }}
        </span>

        <b-overlay
          v-if="file"
          :show="sending"
          rounded
          opacity="0.7"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block me-4"
        >
          <b-button class="my-4 main-btn" @click="removeFile">
            Quitar
          </b-button>
        </b-overlay>

        <b-overlay
          v-if="file"
          :show="sending"
          rounded
          opacity="0.7"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button class="my-4 main-btn" @click="importTemplate">
            Importar
          </b-button>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <label class="label-title"
          >Seleccione un tipo de riesgo para agregar</label
        >
        <select
          class="form-select form-select-sm rounded-0"
          v-model="riskTable"
          @change="addRisk"
        >
          <option :value="null"></option>
          <option v-for="item in riskLevels" :key="item" :value="item">
            {{ $t(`risk-management.risk-level.${item}`) }}
          </option>
        </select>
      </b-col>
    </b-row>
    <b-card
      class="shadow-sm border-0 rounded-0 mt-3 mb-3"
      no-header
      v-if="riskTable && form.length"
    >
      <b-row>
        <h6 class="page-title">
          Agregar {{ $t(`risk-management.risk-level.${riskTable}`) }}
        </h6>
      </b-row>
      <b-row>
        <div>
          <b-row>
            <b-col md="3" v-for="(item, index) in form" :key="index">
              <b-form-group class="mt-3">
                <label class="form-title" :for="item.field">{{
                  $t(`risk-management.fields.${item.field}`)
                }}</label>
                <b-form-input
                  class="rounded-0"
                  v-if="item.type === 'number'"
                  :type="item.type"
                  :value="item.value"
                  @input="updateNumberField(index, $event)"
                  @keypress="preventExtraDecimals"
                />
                <input
                  class="form-control rounded-0"
                  v-if="item.type === 'decimal'"
                  type="text"
                  :value="item.value"
                  @input="updateDecimalField(index, $event)"
                />
                <select
                  class="form-select form-select-sm rounded-0"
                  v-if="item.type === 'select'"
                  :value="item.value"
                  @input="updateFormField(index, $event)"
                >
                  <option
                    v-for="option in item.options"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ $t(`risk-management.descriptions.${option.text}`) }}
                  </option>
                </select>
                <select
                  class="form-select form-select-sm rounded-0"
                  v-if="item.type === 'select-text'"
                  :value="item.value"
                  @input="updateTextField(index, $event)"
                >
                  <option :value="null"></option>
                  <option
                    v-for="option in item.options"
                    :key="option"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
                <select
                  class="form-select form-select-sm rounded-0"
                  v-if="item.type === 'select-data'"
                  :value="item.value"
                  @input="updateFormField(index, $event)"
                >
                  <option
                    v-for="option in item.options"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ $t(`risk-management.descriptions.${descriptions(option.description)}`) }}: {{option.value}}
                  </option>
                </select>
                <div>
                  <input
                    type="checkbox"
                    v-if="item.type === 'checkbox'"
                    :checked="item.value"
                    @change="updateCheckboxField(index, item.value)"
                  >
                  <span class="checkbox-text m-2">
                    {{ $t(`risk-management.fields.${item.field}`) }}
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group class="text-end">
            <b-overlay
              :show="sending"
              rounded
              opacity="0.7"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button class="my-4 main-btn" @click="save">
                <span v-if="id !== null">Editar</span>
                <span v-else>Guardar</span>
              </b-button>
            </b-overlay>
          </b-form-group>
        </div>
      </b-row>
    </b-card>
    <b-row v-if="items.length">
      <div>
        <table
          class="
            table
            b-table
            table-hover table-sm
            b-table-stacked-md b-table-selectable b-table-select-single
            mb-3
          "
        >
          <thead>
            <tr>
              <th>Tipo</th>
              <th
                class="text-center"
                v-for="header in filteredHeaders"
                :key="header"
              >
                {{ $t(`risk-management.fields.${header}`) }}
              </th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>{{ $t(`risk-management.risk-level.${riskTable}`) }}</td>
              <td
                align="center"
                v-for="header in filteredHeaders"
                :key="header"
              >
                <!--     <span
                  v-if="header === 'createdAt' || header === 'lastModifiedAt'"
                >
                  {{ formatDate(item['createdAt']) }}
                </span> -->
                <span v-if="header === 'createdAt'">
                  {{ item["createSuperintendence"] }}<br />
                  {{ formatDate(item["createdAt"]) }}
                </span>
                <span v-else-if="header === 'lastModifiedAt'">
                  {{ item["modifiedSuperintendence"] }}<br />
                  {{ formatDate(item["lastModifiedAt"]) }}
                </span>
                <span v-else-if="header === 'description'">
                  {{
                    $t(
                      `risk-management.descriptions.${formatDescription(
                        item[header]
                      )}`
                    )
                  }}
                </span>
                <span v-else-if="header === 'type'">
                  {{
                    $t(
                      `risk-management.descriptions.${formatType(item[header])}`
                    )
                  }}
                </span>
                <span v-else-if="header === 'status'">
                  {{ item[header] === 0 ? "Activo" : "Inactivo" }}
                </span>
                <span v-else-if="header === 'valueStart'">
                  {{ `&gt;${item[header]}` }}
                </span>
                <span v-else-if="header === 'valueEnd'">
                  {{ `&lt;${item[header]}` }}
                </span>
                <span v-else-if="header === 'isTaxHaven'">
                  {{ item[header] === true ? "Si" : "No" }}
                </span>
                <span v-else>{{ item[header] }}</span>
              </td>
              <td>
                <span v-b-tooltip.hover title="Editar">
                  <b-button @click="editRow(item)" size="sm" class="me-2">
                    <b-icon icon="pencil" />
                  </b-button>
                </span>
                <span v-b-tooltip.hover title="Estado">
                  <b-button
                    @click="changeStatusRow(item)"
                    size="sm"
                    :variant="item.status === 0 ? 'success' : 'danger'"
                  >
                    <b-icon-toggle-on v-if="item.status === 0" />
                    <b-icon-toggle-off v-else />
                  </b-button>
                </span>
              </td>
            </tr>
            <tr v-if="riskTable === 'RiskFinalPercentage'">
              <td colspan="2" align="right">% Total</td>
              <td align="center">
                {{ items.reduce((suma, item) => suma + item.percentage, 0) }}
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-row>

    <hr />

    <RiskTableHistory ref="riskTableHistory" :riskTable="riskTable" />

    <!-- Modal -->
    <b-modal
      v-model="statusModal"
      content-class="rounded-0"
      dialog-class="long-modal"
      header-class="border-0"
      body-bg-variant="light"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <div class="container">
        <p>¿Esta seguro de cambiar el estado?</p>

        <b-row class="text-center mt-2">
          <b-col>
            <b-overlay
              :show="sending"
              rounded
              opacity="0.7"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button
                class="main-btn me-3"
                size="sm"
                @click="updateStatus"
                :disabled="sending"
              >
                Si
              </b-button>
            </b-overlay>
            <b-button size="sm" variant="secondary" @click="closeUpdate"
              >No</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { riskLevels } from "@/config/risk-levels";
import { riskLevelDescriptionBasic } from "@/config/risk-level-description-basic";
import { riskLevelDescriptionIntermediate } from "@/config/risk-level-description-intermediate";
import { riskLevelDescriptionShore } from "@/config/risk-level-description-shore";
import { riskLevelDescription } from "@/config/risk-level-description";
import { countries } from "@/config/countries";
import { activities } from "@/config/activities";
import { riskLevelDescriptionType } from "@/config/risk-level-description-type";
import { legalEntityType } from "@/config/legal-entity-type";
import { societyType } from "@/config/society-type";
import { RepositoryFactory } from "@/services/api";
import { mapActions } from "vuex";
import moment from "moment";
import RiskTableHistory from "./RiskTableHistory.vue";

export default {
  name: "risk-management-table",
  components: {
    RiskTableHistory,
  },
  data() {
    return {
      id: null,
      idForStatus: null,
      changeStatus: null,
      sending: false,
      riskTable: null,
      riskLevels: riskLevels,
      riskLevelDescriptionBasic: riskLevelDescriptionBasic,
      riskLevelDescriptionIntermediate: riskLevelDescriptionIntermediate,
      riskLevelDescriptionShore: riskLevelDescriptionShore,
      riskLevelDescription: riskLevelDescription,
      countries: countries,
      activities: activities,
      riskLevelDescriptionType: riskLevelDescriptionType,
      legalEntityType: legalEntityType,
      societyType: societyType,
      form: [],
      items: [],
      excludedFields: [
        "id",
        "createSuperintendence",
        "modifiedSuperintendence",
      ],
      statusModal: false,
      file: null,
    };
  },
  computed: {
    filteredHeaders() {
      if (this.items.length === 0) return [];
      return Object.keys(this.items[0]).filter(
        (header) => !this.excludedFields.includes(header)
      );
    },
  },
  methods: {
    ...mapActions("modals", ["openModal", "setModalInfo"]),
    changeTab(index) {
      this.tabIndex = index;
    },
    async getTable(riskTable) {
      let response = await RepositoryFactory.riskLevelRepository.getTable(riskTable);
      return response;
    },
    async getData() {
      try {
        let response = await this.getTable(this.riskTable);
        this.items = response.data;
        this.$refs.riskTableHistory.$refs.rTableHistory.refresh();
      } catch (error) {
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Error al consumir servicio.",
        });
      }
    },
    addRisk() {
      this.id = null;
      this.form = [];
      this.closeUpdate();
      switch (this.riskTable) {
        case "RiskLevel":
          this.formVariable(this.riskLevelDescriptionBasic);
          break;
        case "RiskResidentAgent":
          this.formValueVariable(this.riskLevelDescriptionBasic);
          break;
        case "RiskAntiquitySociety":
          this.formValueVariable(this.riskLevelDescriptionBasic);
          break;
        case "RiskCategory":
          this.formValue(this.riskLevelDescriptionBasic);
          break;
        case "RiskCategoryLegalEntity":
          this.formValueSelect(this.legalEntityType, "RiskCategory");
          break;
        case "RiskType":
          this.formValue(this.riskLevelDescriptionBasic);
          break;
        case "RiskSocietyType":
          this.formValueSelect(this.societyType, "RiskType");
          break;
        case "RiskCountry":
          this.formValue(this.riskLevelDescriptionIntermediate);
          break;
        case "RiskForCountry":
          this.formValueSelectTax("RiskCountry");
          break;
        case "RiskOffShore":
          this.formValue(this.riskLevelDescriptionShore);
          break;
        case "RiskEconomicActivity":
          this.formValue(this.riskLevelDescriptionBasic);
          break;
        case "RiskForEconomicActivity":
          this.formTextValueSelect("RiskEconomicActivity");
          break;
        case "RiskFinalPercentage":
          this.formTypeValue();
          break;
      }
      if (this.riskTable !== null) {
        this.getData();
      }
    },
    formVariable(options) {
      this.form = [
        {
          field: "description",
          type: "select",
          options: options,
          value: null,
        },
        {
          field: "valueStart",
          type: "number",
          value: 0,
        },
        {
          field: "valueEnd",
          type: "number",
          value: 0,
        },
      ];
    },
    formValueVariable(options) {
      this.form = [
        {
          field: "description",
          type: "select",
          options: options,
          value: null,
        },
        {
          field: "value",
          type: "number",
          value: 0,
        },
        {
          field: "valueStart",
          type: "number",
          value: 0,
        },
        {
          field: "valueEnd",
          type: "number",
          value: 0,
        },
      ];
    },
    async formValueSelect(options, table) {
      let data = await this.getTable(table);
      this.form = [
        {
          field: "description",
          type: "select",
          options: options,
          value: null,
        },
        {
          field: "value",
          type: "select-data",
          options: data.data,
          value: null,
        }
      ];
    },
    formValue(options) {
      this.form = [
        {
          field: "description",
          type: "select",
          options: options,
          value: null,
        },
        {
          field: "value",
          type: "number",
          value: 0,
        },
      ];
    },
    async formValueSelectTax(table) {
      let data = await this.getTable(table);
      this.form = [
        {
          field: "country",
          type: "select-text",
          options: countries,
          value: null,
        },
        {
          field: "value",
          type: "select-data",
          options: data.data,
          value: null,
        },
        {
          field: "isTaxHaven",
          type: "checkbox",
          value: false,
        },
      ];
    },
    formValueTax() {
      this.form = [
        {
          field: "country",
          type: "select-text",
          options: countries,
          value: null,
        },
        {
          field: "value",
          type: "number",
          value: 0,
        },
        {
          field: "isTaxHaven",
          type: "checkbox",
          value: false,
        },
      ];
    },
    async formTextValueSelect(table) {
      let data = await this.getTable(table);
      this.form = [
        {
          field: "activity",
          type: "select-text",
          options: activities,
          value: null,
        },
        {
          field: "value",
          type: "select-data",
          options: data.data,
          value: null,
        },
      ];
    },
    formTextValue() {
      this.form = [
        {
          field: "activity",
          type: "select-text",
          options: activities,
          value: null,
        },
        {
          field: "value",
          type: "number",
          value: 0,
        },
      ];
    },
    formTypeValue() {
      this.form = [
        {
          field: "type",
          type: "select",
          options: this.riskLevelDescriptionType,
          value: null,
        },
        {
          field: "percentage",
          type: "number",
          value: 0,
        },
      ];
    },
    updateDecimalField(index, event) {
      if (!/^\d*\.?\d*$/.test(event.target.value)) {
        event.target.value = event.target.value.slice(0, -1);
      }
      this.form[index].value = event.target.value;
    },
    updateNumberField(index, event) {
      this.form[index].value = event;
    },
    preventExtraDecimals(event) {
      const inputValue = event.target.value;
      const dotIndex = inputValue.indexOf('.');
      const commaIndex = inputValue.indexOf(',');

      if ((dotIndex !== -1 && inputValue.slice(dotIndex + 1).length >= 2) ||
          (commaIndex !== -1 && inputValue.slice(commaIndex + 1).length >= 2)) {
        event.preventDefault();
      }
    },
    updateFormField(index, event) {
      this.form[index].value = event.target.value * 1;
    },
    updateTextField(index, event) {
      this.form[index].value = event.target.value;
    },
    updateCheckboxField(index, value) {
      this.form[index].value = !value;
    },
    async save() {
      try {
        var payload = {};

        for (let index = 0; index < this.form.length; index++) {
          const element = this.form[index];

          if (element.value === null || element.value === "") {
            var required = this.$t(`risk-management.fields.${element.field}`);
            this.openModal();
            this.setModalInfo({
              title: "Aviso",
              message: `${required} es requerido`,
            });
            return;
          }

          payload[element.field] = element.value;
        }

        let response = null;

        if (this.id !== null) {
          payload["id"] = this.id;
          response = await RepositoryFactory.riskLevelRepository.update(
            payload,
            this.riskTable
          );
        } else {
          response = await RepositoryFactory.riskLevelRepository.add(
            payload,
            this.riskTable
          );
        }

        if (response != null && response.status === 200) {
          this.openModal();
          this.setModalInfo({
            title: "Aviso",
            message: "Registrado con exito",
          });
          this.addRisk();
        } else {
          this.openModal();
          this.setModalInfo({
            title: "Aviso",
            message: "Error al registrar dato",
          });
        }
      } catch (error) {
        let message = "Error al consumir servicio.";

        if (error?.response?.status === 400 && error?.response?.data) {
          console.log(error.response.data);
          message = "";
          if (typeof error.response.data === "object") {
            error.response.data.forEach((item) => {
              message += `<p>${item.value}</p>`;
            });
          } else if (typeof error.response.data === "string") {
            message = this.$t(error.response.data);
          }
        }

        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: message,
        });
      }
    },
    formatDate(date) {
      return date != null
        ? moment.utc(date).local().format("DD/MM/YYYY hh:mm A")
        : date;
    },
    formatDescription(value) {
      if (this.riskTable == "RiskCategoryLegalEntity") {
        return this.legalEntityDesriptions(value);
      } else if (this.riskTable == "RiskSocietyType") {
        return this.societyTypeDesriptions(value);
      } else {
        return this.descriptions(value);
      }
    },
    societyTypeDesriptions(value) {
      return this.societyType.find((x) => x.value === value).text;
    },
    legalEntityDesriptions(value) {
      return this.legalEntityType.find((x) => x.value === value).text;
    },
    descriptions(value) {
      return this.riskLevelDescription.find((x) => x.value === value).text;
    },
    formatType(value) {
      switch (value) {
        case 0:
          return "ResidentAgent";
        case 1:
          return "Country";
        case 2:
          return "AntiquitySociety";
        case 3:
          return "Category";
        case 4:
          return "SocietyType";
        case 5:
          return "EconomicActivity";
        case 6:
          return "TaxHaven";
        default:
          return value;
      }
    },
    editRow(item) {
      this.id = item.id;
      for (let index = 0; index < this.form.length; index++) {
        var value = item[this.form[index].field];
        this.form[index].value = value;
      }
    },
    changeStatusRow(item) {
      this.idForStatus = item.id;
      this.changeStatus = item.status === 0 ? 1 : 0;
      this.statusModal = true;
    },
    async updateStatus() {
      try {
        await RepositoryFactory.riskLevelRepository.updateStatus(
          this.idForStatus,
          this.changeStatus,
          this.riskTable
        );
        this.closeUpdate();
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Estado actualizado.",
        });
        this.getData();
      } catch (error) {
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Error al consumir servicio.",
        });
      }
    },
    closeUpdate() {
      this.idForStatus = null;
      this.changeStatus = null;
      this.statusModal = false;
    },
    async downloadTemplate() {
      try {
        var response =
          await RepositoryFactory.riskLevelRepository.templateExcel();
        if (response.data) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "PlantillaRiesgos.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      } catch (error) {
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Error al consumir servicio.",
        });
      }
    },
    onInputChange(e) {
      this.file = e.target.files[0];
      RepositoryFactory.event.add({
        code: 2004,
        details: "file download"
      });
    },
    removeFile() {
      this.file = null;
      this.$refs.fileTemplate.value = "";
    },
    async importTemplate() {
      try {
        this.sending = true;
        let formData = new FormData();
        formData.append("file", this.file);
        var response = await RepositoryFactory.riskLevelRepository.importExcel(
          formData
        );

        if (response.status === 200) {
          this.openModal();
          this.setModalInfo({
            title: "Aviso",
            message: "Datos importados correctamente.",
          });
          this.removeFile();

          if (this.riskTable !== null) {
            this.getData();
          } else {
            this.$refs.riskTableHistory.$refs.rTableHistory.refresh();
          }
        } else {
          this.openModal();
          this.setModalInfo({
            title: "Aviso",
            message: "Error al consumir servicio.",
          });
        }
      } catch (error) {
        this.openModal();
        if (error.response.status === 400) {
          this.setModalInfo({
            title: "Aviso",
            message: error.response.data,
          });
        } else {
          this.setModalInfo({
            title: "Aviso",
            message: "Error al consumir servicio.",
          });
        }
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>
