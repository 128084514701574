export const riskLevels = [
  "RiskLevel",
  "RiskResidentAgent",
  "RiskAntiquitySociety",
  "RiskCategory",
  "RiskCategoryLegalEntity",
  "RiskType",
  "RiskSocietyType",
  "RiskCountry",
  "RiskForCountry",
  "RiskOffShore",
  "RiskEconomicActivity",
  "RiskForEconomicActivity",
  "RiskFinalPercentage",
];
