<template>
  <div class="mt-3">
    <b-row>
      <h6 class="page-title pb-2 mt-3">Histórico de Riesgos</h6>
    </b-row>

    <b-row>
      <b-col>
        <b-card no-header no-body class="rounded-0 shadow-sm border-0">
          <b-table
            ref="rTableHistory"
            stacked="md"
            small
            hover
            select-mode="single"
            selectable
            show-empty
            empty-text="No hay registros que mostrar"
            :filter="filter"
            :busy.sync="isBusy"
            :fields="fields"
            :items="retrieveData"
            :current-page="currentPage"
            :per-page="take"
          >
            <template #cell(createdAt)="data">
              {{ formattedDate(data.item.createdAt) }}
            </template>
            <template #cell(tableName)="data">
              {{ $t(`risk-management.risk-level.${data.item.tableName}`) }}
            </template>
            <template #cell(payload)="data">
              <div v-if="data.item.payload != null">
                <!-- <div v-if="data.item.payload.Code">Código: {{ data.item.payload.Code }}</div> -->
                <div v-if="data.item.payload.Description != null">
                  Descripción:
                  {{
                    $t(
                      `risk-management.descriptions.${formatDescription(
                        data.item.payload.Description,
                        data.item.tableName
                      )}`
                    )
                  }}
                </div>
                <div v-if="data.item.payload.Activity">
                  Actividad: {{ data.item.payload.Activity }}
                </div>
                <div v-if="data.item.payload.Country">
                  País: {{ data.item.payload.Country }}
                </div>
                <div v-if="data.item.payload.Type != null">
                  Tipo:
                  {{
                    $t(
                      `risk-management.descriptions.${formatType(
                        data.item.payload.Type
                      )}`
                    )
                  }}
                </div>
                <div v-if="data.item.payload.ValueStart != null">
                  Valor incial: {{ data.item.payload.ValueStart }}
                </div>
                <div v-if="data.item.payload.ValueEnd != null">
                  Valor final: {{ data.item.payload.ValueEnd }}
                </div>
                <div v-if="data.item.payload.Value != null">
                  Valor: {{ data.item.payload.Value }}
                </div>
                <div v-if="data.item.payload.IsTaxHaven != null">
                  Paraíso fiscal:
                  {{ data.item.payload.IsTaxHaven === true ? "Si" : "No" }}
                </div>
                <div v-if="data.item.payload.Percentage != null">
                  Porcentage: {{ data.item.payload.Percentage }}
                </div>
                <div>
                  Estado:
                  {{ data.item.payload.Status == 0 ? "Activo" : "Inactivo" }}
                </div>
                <div>
                  Creado:
                  {{ formattedDate(data.item.payload.CreatedAt) }}
                </div>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-4 mb-5">
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="take"
          size="sm"
          class="my-0 justify-content-end"
        >
          <template #first-text>
            <span
              ><b-icon-chevron-double-left
                scale="1.3"
              ></b-icon-chevron-double-left
            ></span>
          </template>
          <template #prev-text>
            <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
          </template>
          <template #next-text>
            <span
              ><b-icon-chevron-right scale="1.3"></b-icon-chevron-right
            ></span>
          </template>
          <template #last-text>
            <span
              ><b-icon-chevron-double-right
                scale="1.3"
              ></b-icon-chevron-double-right
            ></span>
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { riskLevelDescriptionType } from "@/config/risk-level-description-type";
import { legalEntityType } from "@/config/legal-entity-type";
import { societyType } from "@/config/society-type";
import { riskLevelDescription } from "@/config/risk-level-description";
import moment from "moment";

export default {
  name: "risk-table-history",
  props: {
    riskTable: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      riskLevelDescriptionType: riskLevelDescriptionType,
      societyType: societyType,
      legalEntityType: legalEntityType,
      riskLevelDescription: riskLevelDescription,
      fields: [
        { key: "createdAt", label: "Fecha", class: "text-center" },
        { key: "tableName", label: "Tabla" },
        { key: "payload", label: "Datos" },
        { key: "registeredUser", label: "Por" },
      ],
      search: "",
      filter: null,
      isBusy: false,
      currentPage: 1,
      take: 10,
      rows: 1,
    };
  },
  methods: {
    async retrieveData(context) {
      this.isBusy = true;
      try {
        let payload = {
          page: context.currentPage,
          take: 10,
          search: context.filter,
          tableName: this.riskTable,
        };
        let response = await RepositoryFactory.riskLevelRepository.getHistory(
          payload
        );
        this.rows = response.data.total;
        let items = response.data.items;

        items.forEach((x) => {
          x.payload = JSON.parse(x.payload);
        });

        return items;
      } catch (error) {
        window.console.log(error);
      }
      this.isBusy = false;
    },
    formattedDate(date) {
      return moment.utc(date).local().format("DD/MM/YYYY hh:mm A");
    },
    formatDescription(value, table) {
      if (table == "RiskCategoryLegalEntity") {
        return this.legalEntityDesriptions(value);
      } else if (table == "RiskSocietyType") {
        return this.societyTypeDesriptions(value);
      } else {
        return this.descriptions(value);
      }
    },
    societyTypeDesriptions(value) {
      return this.societyType.find((x) => x.value === value).text;
    },
    legalEntityDesriptions(value) {
      return this.legalEntityType.find((x) => x.value === value).text;
    },
    descriptions(value) {
      return this.riskLevelDescription.find((x) => x.value === value).text;
    },
    formatType(value) {
      switch (value) {
        case 0:
          return "ResidentAgent";
        case 1:
          return "Country";
        case 2:
          return "AntiquitySociety";
        case 3:
          return "Category";
        case 4:
          return "SocietyType";
        case 5:
          return "EconomicActivity";
        case 6:
          return "TaxHaven";
        default:
          return value;
      }
    },
  },
};
</script>
