<template>
  <div class="mt-3">
    <b-row>
      <h6 class="page-title pb-2 mt-3">
        {{ $t("risk-management.risk-measurement") }}
      </h6>
    </b-row>
    <b-row class="justify-content-end mb-3" v-if="superUser">
      <b-col class="text-end" v-if="percentage < 100">
        <span class="mr-2">{{ percentage.toFixed(2) }} % de cálculo actual</span>
      </b-col>
      <b-col class="text-end">
        <b-button class="main-btn ml-2" size="sm" @click="recalculate" :disabled="percentage < 100">
          Recalcular
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card no-header no-body class="rounded-0 shadow-sm border-0">
          <b-table ref="riskReport" stacked="md" small hover select-mode="single" selectable show-empty
            :empty-text="'No hay registros que mostrar'" :filter="filter" :busy.sync="isBusy" :fields="fields"
            :items="retrieveData" :current-page="currentPage" :per-page="take" @filtered="onFiltered"
            @row-clicked="selectItem">
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
              <div class="text-center text-primary my-2">
                <strong>Cargando datos...</strong>
              </div>
            </template>
            <template #cell(type)="data">
              <span>
                {{
          $t(
            `risk-management.descriptions.${parseType(data.item.type)}`
          )
        }}
              </span>
            </template>
            <template #cell(status)="data">
              {{ data.item.status === 0 ? "Activo" : "Inactivo" }}
            </template>
            <template #cell(statuslegalEntity)="data">
              {{ data.item.legalEntityStatus === true ? "Activo" : "Inactivo" }}
            </template>
            <template #cell(create)="data">
              {{ parseAndConvertDate(data.item.createUser?.creationDate) }}
            </template>
          </b-table>

          <div class="text-center">
            <p>
              <label class="label-title">Porcentage total</label>:
              {{ percentageTotal }}
            </p>
            <p>
              <label class="label-title">Riesgo calculado total</label>:
              {{ calculatedTotal }}
            </p>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-5">
      <b-col>
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="take" size="sm"
          class="my-0 justify-content-end">
          <template #first-text>
            <span><b-icon-chevron-double-left scale="1.3" /></span>
          </template>
          <template #prev-text>
            <span><b-icon-chevron-left scale="1.3" /></span>
          </template>
          <template #next-text>
            <span><b-icon-chevron-right scale="1.3" /></span>
          </template>
          <template #last-text>
            <span><b-icon-chevron-double-right scale="1.3" /></span>
          </template>
        </b-pagination>
      </b-col>
    </b-row>

    <!-- Modal -->
    <b-modal v-model="recalculateModal" content-class="rounded-0" dialog-class="long-modal" header-class="border-0"
      body-bg-variant="light" centered hide-header hide-footer no-close-on-backdrop>
      <div class="container">
        <p>¿Esta seguro de recalcular las valores?</p>

        <b-row class="text-center mt-2">
          <b-col>
            <b-overlay :show="sending" rounded opacity="0.7" spinner-small spinner-variant="primary"
              class="d-inline-block">
              <b-button class="main-btn me-3" size="sm" @click="confirmRecalculate" :disabled="sending">
                Si
              </b-button>
            </b-overlay>
            <b-button size="sm" variant="secondary" @click="closeRecalculateModal">No</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { legalEntityType } from "@/config/legal-entity-type";
import { riskLevelDescription } from "@/config/risk-level-description";
import { riskLevelDescriptionType } from "@/config/risk-level-description-type";
import { societyType } from "@/config/society-type";
import { RepositoryFactory } from "@/services/api";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "risk-report",
  data() {
    return {
      riskLevelDescriptionType: riskLevelDescriptionType,
      societyType: societyType,
      legalEntityType: legalEntityType,
      riskLevelDescription: riskLevelDescription,
      riskForEconomicActivities: [],
      riskSocietyTypes: [],
      riskAntiquitySocieties: [],
      riskCategoryLegalEntities: [],
      riskForCountries: [],
      usersRegisters: [],
      fields: [
        {
          key: "lawFirmName",
          label: "Abogado/Firma",
        },
        { key: "legalEntityName", label: "Entidad Legal" },
        { key: "legalEntity.folioNumber", label: "Folio" },
        {
          key: "statuslegalEntity",
          label: "Estado de la Entidad",
          class: "text-center",
        },
        {
          key: "type",
          label: "Tipo de Riesgo",
        },
        {
          key: "value",
          label: "Valor de Riesgo",
          class: "text-center",
        },
        {
          key: "percentage",
          label: "Porcentage",
          class: "text-center",
        },
        {
          key: "calculated",
          label: "Riesgo Calculado",
          class: "text-center",
        },
        {
          key: "calculatedTotal",
          label: "Riesgo Calculado Total",
          class: "text-center",
        },
        {
          key: "status",
          label: "Estado del Riesgo Final",
          class: "text-center",
        },
        {
          key: "create",
          label: "Fecha",
          class: "text-center",
        },
      ],
      search: "",
      filter: null,
      filters: {},
      isBusy: false,
      currentPage: 1,
      take: 7,
      rows: 1,
      openedDisable: false,
      percentageTotal: 0,
      calculatedTotal: 0,
      recalculateModal: false,
      sending: false,
      percentage: 100,
      intervalId: null
    };
  },
  computed: {
    ...mapGetters("application", ["isSuperUser"]),
    ...mapGetters("oidcStore", ["oidcUser"]),
    superUser() {
      return this.isSuperUser;
    },
  },
  mounted() {
    this.percentage = 0;
    this.intervalId = setInterval(this.recalculating, 5000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    ...mapActions("modals", ["openModal", "setModalInfo"]),
    closeDisable() {
      this.openedDisable = false;
    },
    filterTable() {
      this.filter = this.search;
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async retrieveData(context) {
      this.isBusy = true;
      try {
        let payload = {
          page: context.currentPage,
          take: this.take,
          search: context.filter,
          filters: this.filters,
        };
        let response = await RepositoryFactory.riskLevelRepository.getFinalRisk(
          payload
        );
        this.rows = response.data.total;
        let items = response.data.items;
        const percentageTotal = items.reduce(
          (acumulador, item) => acumulador + item.percentage,
          0
        );
        const calculatedTotal = items.reduce(
          (acumulador, item) => acumulador + item.calculated,
          0
        );
        this.percentageTotal = percentageTotal;
        this.calculatedTotal = calculatedTotal.toFixed(2);

        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          item.lawFirmName = item.lawFirmName.toUpperCase()
        }

        return items;
      } catch (error) {
        window.console.log(error);
      }
      this.isBusy = false;
    },
    refreshTable() {
      this.$refs.riskReport.refresh();
    },
    parseType(value) {
      return this.riskLevelDescriptionType.find((x) => x.value === value).text;
    },
    selectItem(item) {
      console.log(item);
    },
    recalculate() {
      this.recalculateModal = true;
    },
    closeRecalculateModal() {
      this.recalculateModal = false;
    },
    async confirmRecalculate() {
      try {
        this.sending = true;
        await RepositoryFactory.riskLevelRepository.finalRecalculateTemp({ email: this.oidcUser.email });
        this.closeRecalculateModal();
        this.$refs.riskReport.refresh();
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message:
            "Se ha iniciado el proceso de recalcular, se le notificara a su correo electrónico cuando el proceso culmine, recuerde que tardara en función a la cantidad de registros del sistema.",
        });
        this.percentage = 0;
        await this.recalculating();
      } catch (error) {
        window.console.log(error);
      } finally {
        this.sending = false;
      }
    },
    descriptions(value) {
      return this.riskLevelDescription.find((x) => x.value === value).text;
    },
    parseAndConvertDate(date) {
      return date != null || date != "" ? moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss") : date;
    },
    async recalculating() {
      if (this.percentage < 100) {
        try {
          var response = await RepositoryFactory.riskLevelRepository.calculationPercentage();
          this.percentage = response.data;
        } catch (error) {
          window.console.log(error);
        }
      }
    },
  },
};
</script>

<style scoped>
.label-title {
  font-size: 14px;
  font-weight: 500;
  color: #717171;
  margin-bottom: 3px;
}
</style>
